import { useCallback, useEffect, useState } from 'react';
import { DataGridPro, useGridApiRef, getGridStringOperators, getGridDateOperators } from '@mui/x-data-grid-pro';
import { Avatar, Badge, Box, Button, Card, CardContent, CardHeader, Divider, Link, List, ListItem, ListItemIcon, ListItemText, Paper, Tooltip, tooltipClasses } from '@mui/material';
import { AssignmentTurnedInOutlined, AttachFile, BlurOnOutlined, Launch, MedicalInformationOutlined, NoteAddOutlined, PersonOutline, SouthAmericaOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { DateTime } from 'luxon';

import theme from './theme';


const dateGetter = (params) => {
    const d = DateTime.fromISO(params.value, { zone: 'utc' });
    return d.isValid ? d.toJSDate() : null;
};

const formatToDateTime = (params) => {
    const d = DateTime.fromJSDate(params.value, { zone: 'utc' });
    return d.isValid ? d.setZone('America/Sao_Paulo').toFormat('dd/MM/yyyy HH:mm') : '';
};

const ListTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.grey['800'],
        boxShadow: theme.shadows[1],
        maxHeight: 600,
        overflow: 'auto',
        fontWeight: 'normal'
    },
}));

const DetailCard = (params) => {
    const Icon = params.icon;
    return (<>
        <Card sx={{ flex: 1, m: 1 }}>
            <CardHeader avatar={<Avatar sx={{ width: 30, height: 30, bgcolor: 'primary.main' }}><Icon fontSize='small' /></Avatar>} title={params.title} />
            <CardContent sx={{ pt: 0 }}>{params.children}</CardContent>
        </Card>
    </>);
};

const DetailItems = (params) => {
    if (Array.isArray(params.value) && params.value.length) {
        return (<>
            <List dense={true} sx={{ p: 0 }}>
                {params.value.map((p, i) => (
                    <ListItem key={'item' + i} sx={{ p: 0 }}>
                        <ListItemIcon sx={{ minWidth: 'auto', pr: 1 }}><BlurOnOutlined sx={{ fontSize: '10px' }} color='primary' /></ListItemIcon>
                        <ListItemText primary={p.name} />
                    </ListItem>
                ))}
            </List>
        </>);
    }
    return '';
}

const DetailAttachments = (params) => {
    if (Array.isArray(params.value) && params.value.length) {
        return (<>
            <List dense={true} sx={{ p: 0 }}>
                {params.value.map((p) => (
                    <ListItem key={p.link} sx={{ p: 0 }}>
                        <ListItemIcon sx={{ minWidth: 'auto', pr: 1 }}><BlurOnOutlined sx={{ fontSize: '10px' }} color='primary' /></ListItemIcon>
                        <ListItemText primary={<Link href={p.link} underline='none' target='_blank' rel='noreferrer'>{p.name}</Link>} />
                    </ListItem>
                ))}
            </List>
        </>);
    }
    return '';
}

const tooltipRenderer = (params) => {
    if (params.value.length) {
        return <ListTooltip placement='bottom' title={params.value}><Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value}</Box></ListTooltip>;
    }
    return '';
};

const itemsRenderer = (params) => {
    if (Array.isArray(params.value) && params.value.length) {
        return <ListTooltip placement='right' title={(<List dense={true}>
            {params.value.map((p, i) => (<ListItem key={'item' + i} sx={{ p: 0 }}>
                <ListItemIcon sx={{ minWidth: 0, p: 1 }}><BlurOnOutlined sx={{ fontSize: '10px' }} color='primary' /></ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: 'common.white' }} primary={p.name} />
            </ListItem>))}
        </List>)}><Badge badgeContent={params.value.length} color='primary'><AssignmentTurnedInOutlined color='action' /></Badge></ListTooltip>;
    }
    return '';
};

const attachmentsRenderer = (params) => {
    if (Array.isArray(params.value) && params.value.length) {
        return <ListTooltip placement='right' title={(<List dense={true}>
            {params.value.map((p, i) => (<ListItem key={'item' + i} sx={{ p: 0 }}>
                <ListItemIcon sx={{ minWidth: 0, p: 1 }}><BlurOnOutlined sx={{ fontSize: '10px' }} color='primary' /></ListItemIcon>
                <ListItemText primary={<Link href={p.link} underline='none' target='_blank' rel='noreferrer' sx={{color: 'common.white'}}>{p.name}</Link>} />
            </ListItem>))}
        </List>)}><Badge badgeContent={params.value.length} color='primary'><AttachFile color='action' /></Badge></ListTooltip>;
    }
    return '';
};

const urlRenderer = (params) => {
    return <Link href={params.value} underline='none' target='_blank' rel='noreferrer'><Launch color='primary' /></Link>;
};

const containsOperators = getGridStringOperators().filter(o => o.value === 'contains');
const equalsOperators = getGridStringOperators().filter(o => o.value === 'equals');
const createdAtOperators = getGridDateOperators().filter(o => ['after', 'onOrAfter', 'before', 'onOrBefore'].indexOf(o.value) !== -1);

const columns = [
    { field: 'portal', headerName: 'Portal', filterOperators: containsOperators, headerClassName: 'hiddenFilterOptions' },
    { field: 'ref', headerName: 'ID', filterOperators: equalsOperators, headerClassName: 'hiddenFilterOptions' },
    { field: 'status', headerName: 'Status', filterOperators: containsOperators, headerClassName: 'hiddenFilterOptions', width: 250, renderCell: tooltipRenderer },
    { field: 'created_at', headerName: 'Captured', filterOperators: createdAtOperators, width: 200, type: 'date', valueGetter: dateGetter, valueFormatter: formatToDateTime },
    { field: 'customer', headerName: 'Customer', filterOperators: containsOperators, headerClassName: 'hiddenFilterOptions', flex: 1, renderCell: tooltipRenderer },
    { field: 'hospital', headerName: 'Hospital', filterOperators: containsOperators, headerClassName: 'hiddenFilterOptions', flex: 1, renderCell: tooltipRenderer },
    { field: 'items', headerName: 'Items', width: 60, sortable: false, filterable: false, renderCell: itemsRenderer, align: 'center' },
    { field: 'attachments', headerName: 'Attachments', width: 100, sortable: false, filterable: false, renderCell: attachmentsRenderer, align: 'center' },
    { field: 'link', headerName: 'Link', width: 60, sortable: false, filterable: false, renderCell: urlRenderer, align: 'center' },
];

export default function Opportunities({ setUser, page }) {

    const apiRef = useGridApiRef();
    // window.AAA = apiRef;
    // save state: AAA.current.exportState()
    // load state: pass saved state into {initialState}
    // restore: apiRef.current.restoreState(state)
    // https://mui.com/x/react-data-grid/state/#restore-the-state-with-apiref
    const state = {};

    const [data, setData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(true);

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100,
    });
    const [filterModel, setFilterModel] = useState({
        items: [],
    });
    const [sortModel, setSortModel] = useState([
        {field: "created_at", sort: "desc"}
    ]);

    const loadData = async () => {
        setLoading(true);
        const req = await fetch('/api/' + page.entity, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                page: paginationModel.page,
                pageSize: paginationModel.pageSize,
                sortModel,
                filterModel,
            }),
        });
        const resp = await req.json();
        if (resp.rows) {
            setData(resp.rows);
            setRowCount(resp.count);
        } else if (resp.error) {
            setUser(null);
        }
        setLoading(false);
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, paginationModel, sortModel, filterModel]);

    const getDetailPanelContent = useCallback(({ row }) => {
        return (<>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <DetailCard icon={PersonOutline} title='Patient'>{row.patient}</DetailCard>
                <DetailCard icon={SouthAmericaOutlined} title='State'>{row.state}</DetailCard>
                <DetailCard icon={MedicalInformationOutlined} title='Doctor'>{row.doctor}</DetailCard>
                <DetailCard icon={NoteAddOutlined} title='HMO'>{row.insurance}</DetailCard>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', mt: -1 }}>
                <DetailCard icon={AssignmentTurnedInOutlined} title='Items'><DetailItems value={row.items} /></DetailCard>
                <DetailCard icon={AttachFile} title='Attachments'><DetailAttachments value={row.attachments} /></DetailCard>
            </Box>
            <Divider sx={{ backgroundColor: theme.palette.primary.dark, height: '0.1rem' }} />
        </>);
    }, []);

    return <Paper sx={{
        height: '100%',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 3,
    }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1>List of {page.title}</h1>
            <Button onClick={loadData} variant='outlined' color='primary' sx={{ alignSelf: 'center' }}>Refresh</Button>
        </Box>
        <DataGridPro apiRef={apiRef} loading={loading} initialState={state} pagination={true} pageSizeOptions={[250, 100, 50, 25]} disableSelectionOnClick={true}
            unstable_headerFilters={true}
            disableColumnFilter={true}
            sortingMode="server"
            filterMode="server"
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            rows={data} rowCount={rowCount} columns={columns}
            getDetailPanelContent={getDetailPanelContent} getDetailPanelHeight={() => 'auto'}
            sx={{
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
                    outline: 'none',
                },
                '&.MuiDataGrid-root': {
                    '--unstable_DataGrid-headWeight': 600
                },
                '&.MuiDataGrid-root .MuiDataGrid-headerFilterRow .hiddenFilterOptions button[title="Operator"]': {
                    display: 'none'
                },
            }} />
    </Paper>
};
