import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import App from './App';
import theme from './theme';

LicenseInfo.setLicenseKey('d7b5a3456940fd16f8433fe0ce970ab9Tz0zNDk4OTgsRT0xNzM1Njg5NjAwMDAwLFM9cHJvLExNPXBlcnBldHVhbCxLVj0y');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <App />
        </ThemeProvider>
    </React.StrictMode>
);
