import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, ThemeProvider } from '@mui/material';
import { darkTheme } from './theme';


export default function Menu({ menuItems, selectedPage, setSelectedPage }) {

    return (<>
        <ThemeProvider theme={darkTheme}>
            {menuItems.map((m) =>
                <List key={m.title} sx={{ width: '100%' }}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => setSelectedPage(m)} sx={{ borderRadius: 2, color: darkTheme.palette.text.primary, background: selectedPage.entity === m.entity ? darkTheme.palette.primary.dark : '' }}>
                            <ListItemIcon><m.icon /></ListItemIcon>
                            <ListItemText primary={m.title} />
                        </ListItemButton>
                    </ListItem>
                </List>
            )}
        </ThemeProvider>
    </>)
};
