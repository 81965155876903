import { useEffect, useState } from 'react';
import { Box, Button, Divider, Drawer } from '@mui/material';
import { FiberNew, LocalOffer } from '@mui/icons-material';

import theme from './theme';
import Login from './Login';
import Menu from './Menu';
import Opportunities from './Opportunities';

const menuItems = [
    { title: 'New Opportunities', entity: 'opportunities', icon: FiberNew },
    { title: 'Status Updates of Replied Opportunities', entity: 'orders', icon: LocalOffer },
];

export default function App() {
    const [user, setUser] = useState(null);
    const [selectedPage, setSelectedPage] = useState(menuItems[0]);

    useEffect(() => {
        async function checkUser() {
            const req = await fetch('/api/user');
            const resp = await req.json();
            if (resp.user) {
                setUser(resp.user);
            }
        }
        checkUser();
    }, []);

    async function signOut() {
        setUser(null);
        fetch('/api/sign-out');
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {!user ? <Login setUser={setUser} />
                :
                <>
                    <Drawer variant='permanent' sx={{
                        width: 250,
                        m: '1rem',
                        '& .MuiDrawer-paper': {
                            background: 'linear-gradient(195deg, #42424a, #191919)',
                            width: 250,
                            flexDirection: 'column',
                            p: 3,
                            pt: 2,
                            m: '1rem',
                            height: 'calc(100vh - 2rem)',
                            borderRadius: 3,
                        },
                    }}>
                        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'center' }}>
                            <img src="/logo.png" width={110} alt="Edwards logo" style={{ border: '10px solid white' }} />
                            <Divider flexItem sx={{ my: 3, borderColor: 'transparent', borderWidth: 0, height: '0.06rem', backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), ' + theme.palette.primary.main + ', rgba(255, 255, 255, 0))!important' }} />
                            <Menu menuItems={menuItems} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
                        </Box>
                        <Box sx={{ display: 'flex', flexShrink: 1, flexDirection: 'column', alignItems: 'center' }}>
                            <Divider flexItem sx={{ my: 3, borderColor: 'transparent', borderWidth: 0, height: '0.06rem', backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), ' + theme.palette.primary.main + ', rgba(255, 255, 255, 0))!important' }} />
                            {user && <Box sx={{ color: 'white' }}>{user.name}</Box>}
                            {user && <Button onClick={signOut}>Sign Out</Button>}
                        </Box>
                    </Drawer>
                    <Box sx={{ m: '1rem', height: 'calc(100vh - 2rem)', width: 'calc(100vw - 4rem - 250px)' }}>
                        <Opportunities page={selectedPage} setUser={setUser} />
                    </Box>
                </>}
        </Box >
    );
};
