import { createTheme } from "@mui/material";

const settings = {
    palette: {
        primary: { main: '#C8102E' },
        secondary: { main: '#407fde' },
        background: { default: '#f0f2f5' },
    }
}
const theme = createTheme(settings);

settings.palette.mode = 'dark';
const darkTheme = createTheme(settings);

export default theme;
export { darkTheme };
