import { Avatar, Button, Box, Dialog, DialogTitle, DialogContent, TextField } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';

export default function Login({ setUser }) {

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const resp = await fetch('/api/sign-in', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                email: data.get('email'),
                password: data.get('password'),
            })
        });
        const j = await resp.json();
        if (j.user) {
            setUser(j.user);
        } else {
            alert('Login error');
        }
    };

    return (
        <Dialog open={true} fullWidth={true}>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}><Avatar sx={{ mr: 1, bgcolor: 'primary.main' }}><LockOutlined /></Avatar>Sign In</Box>
                <img src="/logo.png" height={70} alt="Edwards logo" />
            </DialogTitle>
            <DialogContent>
                <Box component="form" onSubmit={handleSubmit}
                    noValidate sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="E-mail Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>);
};
